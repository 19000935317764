import { useContext } from "react";
import { Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";

import { UserContext } from "./UserContext";

import logout from "../../assets/images/logout.svg";
import rapIcon from "../../assets/images/rapIconFinal.svg";
import sidebarItems from "../../controllers/sidebarElements";

function Sidebar() {
  const { role } = useContext(UserContext);
  const { push } = useHistory();
  const location = useLocation();
  const history = useHistory();
  const logoutHandler = () => {
    localStorage.clear();
    history.push("/login");
  };

  const pathCheck = location.pathname.replace("/", "").split("/");

  return (
    <div className="sidebarContainer">
      <Row className="iconContainer">
        <Col className="navigationIcon">
          <Row>
            <Col className="icon">
              <Image className="logo" src={rapIcon} />
            </Col>
            {sidebarItems
              .filter((el) => el.users.includes(role))
              .map((item) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={item.route}
                  className={
                    pathCheck[0] === item.route
                      ? "sdr_item sdr_active"
                      : "sdr_item"
                  }
                  onClick={() => push(`/${item.route}`)}
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        <strong>{item.label}</strong>
                      </Tooltip>
                    }
                  >
                    <img src={item.image} alt={item.alt} width={item.width} />
                  </OverlayTrigger>
                </div>
              ))}
          </Row>
          <Row className="icon">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip className="bg-white">
                  <strong>Logout</strong>.
                </Tooltip>
              }
            >
              <Image
                className="icon-logo sdr_item"
                onClick={logoutHandler}
                src={logout}
              />
            </OverlayTrigger>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default Sidebar;
