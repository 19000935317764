import { useContext } from "react";
import { useHistory } from "react-router-dom";

import DropdownSelect from "./DropdownSelect";
import { UserContext } from "./UserContext";
import "./index.css";

import logout from "../../assets/images/logout.svg";

function TopBar() {
  const { name, role } = useContext(UserContext);
  const history = useHistory();

  const logoutHandler = () => {
    localStorage.clear();
    history.push("/login");
  };
  let formattedName: string = name
    .split(/\s/)
    .reduce((response, word) => response + word.slice(0, 1), "")
    .toUpperCase();
  formattedName =
    formattedName.length === 1
      ? name.substring(0, 2).toUpperCase()
      : formattedName;
  const array = [
    {
      name: role,
    },
    {
      name: "Logout",
      image: logout,
      clickEvent: logoutHandler,
    },
  ];
  return (
    <div className="top-bar-height shadow-sm d-flex align-items-center">
      <div className="user-name">
        {name.charAt(0).toUpperCase() + name.substring(1)}
      </div>
      <DropdownSelect
        data={formattedName}
        role={array}
        className="rounded-circle p-2"
      />
    </div>
  );
}
export default TopBar;
