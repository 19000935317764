import { omit } from "ramda";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as Submit } from "../../assets/images/submit.svg";
import { getFormApi, submitFormApi } from "../../controllers/form";
import { catchHandler } from "../../utils";
import { Option } from "../common/type";

// css import
import "./FormSubmit.css";

interface Question {
  question: string;
  questionType: string;
  uniqueName: string;
  options: Option[];
  isRequired: boolean;
  answer?: string;
}

function FormSubmitted() {
  return (
    <div className="main-container">
      <div className="check-container">
        <div className="check-background">
          <Submit className="submitted_form_svg" />
        </div>
        <div className="check-shadow" />
      </div>
      <div style={{ fontSize: "3rem" }}>Form Submitted Successfully</div>
    </div>
  );
}

function FormSubmit() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [form, setForm] = useState<Record<string, any>>({});
  const [questions, setQuestions] = useState<Question[]>([]);
  const [formNotValid, setFormNotValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  useEffect(() => {
    getFormApi(formId)
      .then((res) => {
        setForm(res.data);
        setQuestions(res.data.metaData);
      })
      .catch(catchHandler);
  }, [formId]);

  useEffect(() => {
    const validFormStatus = questions.reduce(
      (prevStatus: boolean, item: Question) => {
        if (prevStatus) return true;
        if (item.isRequired && !item.answer?.trim()) return true;
        return prevStatus;
      },
      false
    );
    setFormNotValid(validFormStatus);
  }, [questions]);

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        history.push("/login");
      }, 1000 * 10);
    }
  }, [submitted, history]);

  const submitForm = () => {
    const mappedData = questions.map((item) => ({
      [item.uniqueName]: item.answer,
    }));
    const data = Object.assign({}, ...mappedData);
    submitFormApi(form.queueId.queueName, data)
      .then((res) => {
        if (res) {
          setSubmitted(true);
        }
      })
      .catch(catchHandler);
  };

  const saveAnswers = (questionIndex: number, answer: string) => {
    const questionWithAnswer = questions.map(
      (item: Question, index: number) => {
        if (index === questionIndex) {
          if (answer !== "") {
            return { ...item, answer };
          }
          return omit(["answer"], item);
        }
        return item;
      }
    );

    setQuestions(questionWithAnswer);
  };

  const questionTransform = (data: Question, index: number) => {
    const { options } = data;

    return (
      <div style={{ marginTop: "20px" }} key={index}>
        <div className="edit_show">
          <div className="question_input_div">
            <div className="top_div">
              <div
                className={
                  data.isRequired
                    ? "input_div form-group required"
                    : "input_div"
                }
              >
                <h1
                  className={
                    data.isRequired
                      ? "question_input control-label"
                      : "question_input"
                  }
                >
                  {data.question}
                </h1>
              </div>
            </div>
            <div className="mid_div">
              {/* Multi Select Div */}
              {data.questionType === "multiple_choice" && (
                <div className="options_div">
                  {options.map((item: Option, optionIndex: number) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="option_edit" key={optionIndex}>
                        <Form.Check
                          type="radio"
                          name={`Question ${index}`}
                          value={item.option}
                          inline
                          label={item.option}
                          id={item.option}
                          onClick={(e) => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            saveAnswers(index, e.target.value);
                          }}
                          required={data.isRequired}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Short answer Div */}
              {data.questionType === "short_answer" && (
                <div className="options_div">
                  <div className="value_edit_show">
                    <input
                      className="input_show"
                      placeholder="Enter Your Answer"
                      onChange={(e) => saveAnswers(index, e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return submitted ? (
    <FormSubmitted />
  ) : (
    <div className="question_form">
      <br />
      <div className="section">
        <div className="question_title_section">
          <div className="question_form_top">
            <h4 className="question_form_top_name">{form.title}</h4>
            <h4 className="question_form_top_desc">{form.description}</h4>
          </div>
          <div className="questions_comp">
            {questions?.map(questionTransform)}
            <div className="primary_btn_div text-center">
              <Button
                variant="primary"
                onClick={submitForm}
                disabled={formNotValid}
              >
                Submit Form
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSubmit;
