import { useContext, useRef, useState } from "react";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { UserContext } from "./UserContext";
import { USER_ROLE_LABELS } from "./globalValues";
import "./index.css";

interface Props {
  data: string;
  role?: unknown;
  className?: string;
}

function DropdownSelect({ data }: Props) {
  const [show, setShow] = useState(false);
  const logoutBoxRef = useRef<HTMLDivElement>(null);

  const userContextValue = useContext(UserContext);
  const { name, email } = userContextValue;
  const role = userContextValue.role as keyof typeof USER_ROLE_LABELS;

  const trimmedMail = email.substring(0, 15);

  const handleClickOutside = () => {
    setShow(false);
  };

  useOnClickOutside(logoutBoxRef, handleClickOutside);

  return (
    <div className="logout-container">
      <button
        className={
          data.length === 2
            ? "user-logo rounded-circle p-2"
            : "user-logo-length rounded-circle p-2"
        }
        type="button"
        onClick={() => setShow(!show)}
      >
        {data}
      </button>
      <div ref={logoutBoxRef} className={show ? "showDiv" : "closeDiv"}>
        <div className="user-img-cont">
          <button className="lg-user-logo rounded-circle" type="button">
            {data}
          </button>
        </div>
        <div className="details-con">
          <p className="lg-user-name">{name}</p>
          <p className="lg-user-role">{USER_ROLE_LABELS[role]}</p>
          {email.length > 19 ? (
            <span>{trimmedMail}...</span>
          ) : (
            <p className="lg-user-mail">{email}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default DropdownSelect;
