import NoDataImg from "../../assets/icons/noData.svg";
import "./index.css";

type NoDataFoundPropsT = {
  message1: string;
  message2: string;
};

function NoDataFound({ message1, message2 }: NoDataFoundPropsT) {
  return (
    <div className="noDataContainer">
      <img src={NoDataImg} alt="No Data" width={200} height={200} />
      <div className="noDataMessageContainer">
        <p className="noDataMessage">{message1}</p>
        <p className="noDataMessage">{message2}</p>
      </div>
    </div>
  );
}

export default NoDataFound;
