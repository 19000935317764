import axios from "axios";

import { toastify } from "../components/common/notification";

import { url } from ".";

export const licenseCheck = () =>
  axios.get(`${url}/license/check`, {}).then((res) => res.data);

export const licenseApi = (token: string) =>
  axios
    .get(`${url}/license`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const signLicense = ({
  instanceId,
  partnerId,
  publicKey,
}: {
  instanceId: string;
  partnerId: string;
  publicKey: string;
}) =>
  axios
    .post(`${url}/license/create`, { instanceId, partnerId, publicKey })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
