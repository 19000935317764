import axios from "axios";

import { toastify } from "../components/common/notification";

import { url } from ".";

export type UserT = {
  _id: string;
  name: string;
  email: string;
  role: string;
  granted_organization: string[];
  granted_agents: string[];
  active_status: boolean;
  created_by: string;
};

export const selfUser = (token: string) =>
  axios
    .get(`${url}/user/me`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const getOneUserApi = (token: string, id: string) =>
  axios
    .get(`${url}/user/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

//   export const forgetPassword = (email: string) =>
// axios
//   .post(`${url}/user/forget-password`, { email })
//   .then((res) => res.data);

export const userListApi = (
  token: string
): Promise<{
  data: UserT[];
}> =>
  axios
    .get(`${url}/user`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationUserListApi = (token: string, orgId: string) =>
  axios
    .get(`${url}/user/org/${orgId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationDeveloperListApi = (token: string, orgId: string) =>
  axios
    .get(`${url}/user/org/${orgId}/developer`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const createUserApi = (token: string, data: object) =>
  axios
    .post(`${url}/user`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const updateUserAgentApi = (token: string, data: object, id: string) =>
  axios
    .put(`${url}/user/agentUpdate/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const updateUserApi = (token: string, data: object, id: string) =>
  axios
    .put(`${url}/user/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const forgetPassword = (email: string) =>
  axios.post(`${url}/forgot-password`, { email }).then((res) => res.data);

export const resetPassword = (token: string, password: string) =>
  axios
    .post(
      `${url}/user/reset-password`,
      { password },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const updatePassword = (token: string, password: string) =>
  axios
    .post(
      `${url}/user/update-password`,
      { password },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const agentUserApi = (token: string, data: object) =>
  axios
    .post(`${url}/user/agentCreate`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const userDeleteApi = (token: string, id: string) =>
  axios
    .delete(`${url}/user/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
