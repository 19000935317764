import axios from "axios";

import { AgentTypeT } from "../components/common/globalValues";
import { toastify } from "../components/common/notification";

import { url } from ".";

type DocumentT = {
  _id: string;
};

export interface IOrganizationDetails extends DocumentT {
  name: string;
  type: AgentTypeT;
  deployed: boolean;
  active_status: boolean;
  connected: boolean;
  tasks: { manual_hours: string }[];
  timezone: string;
  runningTask: {
    name: string;
  };
}

interface IAgentTask extends DocumentT {
  task: string;
  imported_by: {
    _id: string;
    name: string;
    email: string;
    role: string;
  };
  imported_by_name: string;
  version: number;
  config: {
    id: string;
    value: string;
  }[];
  manual_hours: number;
}

export interface IAgent extends DocumentT {
  name: string;
  type: AgentTypeT;
  timezone: string;
  granted_organization: string;
  tasks: IAgentTask[];
  active_status: boolean;
  connected: boolean;
  deleted_status: boolean;
  deployed: boolean;
  runningTask: { name: string };
  created_by: string;
  createdAt: string;
  updatedAt: string;
}

export const organizationListApi = (token: string) =>
  axios
    .get(`${url}/org`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const createOrganizationApi = (token: string, params: unknown) =>
  axios
    .post(`${url}/org`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationDetailsApi = (token: string, orgId: string) =>
  axios
    .get<{ data: IOrganizationDetails[] }>(`${url}/org/${orgId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      );
      return { data: [] };
    });

export const organizationDevelopersApi = (token: string, orgId: string) =>
  axios
    .get(`${url}/user/org/${orgId}/developer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationBotsListerApi = (token: string, orgId: string) =>
  axios
    .get<{ data: IAgent[] }>(`${url}/agent/${orgId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      );
      return { data: [] };
    });

export const botsListApi = (token: string) =>
  axios
    .get(`${url}/agent`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationAdminApi = (token: string, orgId: string) =>
  axios
    .get(`${url}/user/org/${orgId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const updateOrgApi = (token: string, data: unknown, id: string) =>
  axios
    .put(`${url}/org/${id}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const deleteOrgApi = (token: string, id: string) =>
  axios
    .delete(`${url}/org/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationQueueApi = (token: string, id: string) =>
  axios
    .get(`${url}/queue/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const addOrganizationQueueApi = (token: string, data: unknown) =>
  axios
    .post(`${url}/queue/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 201) {
        return res.data;
      }
      toastify("failure", res.data);
      return undefined;
    })
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const organizationAPIKeyApi = (token: string, id: string) =>
  axios
    .get(`${url}/apiKey/${id}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const createAPIKey = (token: string, data: unknown) =>
  axios
    .post(`${url}/apiKey/generate`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const getActiveAgentCount = (token: string, orgId: string) =>
  axios
    .get(`${url}/agent/${orgId}/active/count`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
