import books from "../assets/images/books.svg";
import briefcase from "../assets/images/briefcase.svg";
import dashboard from "../assets/images/dashboard.svg";
import robot from "../assets/images/robot.svg";
import users from "../assets/images/users.svg";

const sidebarItems = [
  {
    label: "Dashboard",
    value: "dashboard",
    route: "dashboard",
    image: dashboard,
    alt: "Dashboard",
    width: "20px",
    users: ["super_admin", "agent_admin", "organization_admin"],
  },
  {
    label: "Agent Screen",
    value: "botScreen",
    route: "botscreen",
    image: robot,
    alt: "Agent Screen",
    width: "20px",
    users: ["agent_admin"],
  },
  {
    label: "Organization",
    value: "organization",
    route: "organisation",
    image: briefcase,
    alt: "Organization",
    width: "20px",
    users: ["super_admin", "organization_admin"],
  },
  {
    label: "User Management",
    value: "users",
    route: "users",
    image: users,
    alt: "Users",
    width: "20px",
    users: ["super_admin", "organization_admin"],
  },
  {
    label: "Task Library",
    value: "task_library",
    route: "task_library",
    image: books,
    alt: "Task Library",
    width: "20px",
    users: ["organization_admin", "agent_admin"],
  },
];

export default sidebarItems;
