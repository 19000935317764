import { createContext, Dispatch } from "react";

export const UserContext = createContext<{
  name: string;
  role: string;
  email: string;
  authToken: string;
  granted_organization: string[];
  setUser: Dispatch<{
    id: "";
    email: "";
    name: "";
    role: "";
    granted_organization: [];
    authToken: "";
  }>;
}>({
  name: "",
  role: "",
  email: "",
  authToken: "",
  granted_organization: [],
  setUser: () => {
    /* do nothing */
  },
});

export default UserContext;
