import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";

import { licenseCheck, signLicense } from "../../controllers/license";
import { UserContext } from "../common/UserContext";

function SignLicense() {
  const [partner, setPartner] = useState("");
  const [instance, setInstance] = useState("");
  const [key, setKey] = useState("");

  const history = useHistory();
  const { authToken } = useContext(UserContext);

  useEffect(() => {
    if (authToken === "") {
      history.push("/login");
    } else {
      licenseCheck().then((licenseData: { valid: boolean }) => {
        if (licenseData.valid) {
          history.push("/dashboard");
        }
      });
    }
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    signLicense({
      instanceId: instance,
      partnerId: partner,
      publicKey: key.replace(/\\n/g, "\n"),
    }).then(() => {
      history.push("/dashboard");
    });
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        marginTop: "10rem",
        justifyContent: "center",
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <h1
            style={{
              marginBottom: "3rem",
              fontWeight: "600",
            }}
          >
            Please Provide your license details.
          </h1>
          <Form.Label>Partner Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Partner Id"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            value={partner}
            onChange={(event) => setPartner(event.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Instance Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Instance Id"
            value={instance}
            onChange={(event) => setInstance(event.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Encryption Key</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            type="text"
            placeholder="Enter Encryption Key"
            value={key}
            onChange={(event) => setKey(event.target.value)}
          />
        </Form.Group>
        <div className="text-center">
          <Button
            variant="primary"
            type="submit"
            style={{
              width: "15rem",
              height: "3rem",

              backgroundColor: "blue",
            }}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default SignLicense;
