export const LABELS = {
  single_task_agent: "Single Agent",
  single_attended: "Single task Attended",
  single_unattended: "Single task UnAttended",
  multi_agent: "Multi Agent",
  multiple_task_agent: "Multi Agent",
  multi_attended: "Multi Attended",
  multi_unattended: "Multi UnAttended",
  single_task: "Single task Agent",
  single_task_attended: "Single task Attended Agent",
  single_task_unattended: "Single task Unattended Agent",
  multiple_task: "Multiple task Agent",
  multiple_task_attended: "Multiple task Attended Agent",
  multiple_task_unattended: "Multiple task Unattended Agent",
} as const;

export const AGENT_TYPES_LABELS = {
  single_task: "Single task Agent",
  single_task_attended: "Single task attended Agent",
  single_task_unattended: "Single task unattended Agent",
  multiple_task: "Multiple task Agent",
  multiple_task_attended: "Multiple task attended Agent",
  multiple_task_unattended: "Multiple task unattended Agent",
} as const;

export const DEFAULT_AGENT_TYPES = [
  "single_task_attended",
  "single_task_unattended",
  "multiple_task_attended",
  "multiple_task_unattended",
] as const;

export const USER_ROLE_LABELS = {
  super_admin: "Super Admin",
  organization_admin: "Organization Admin",
  agent_admin: "Agent Admin",
  developer: "Developer",
} as const;

export type AgentTypeT = keyof typeof AGENT_TYPES_LABELS;
