import axios from "axios";

import { toastify } from "../components/common/notification";

import { url } from ".";

export const agentListApi = (token: string) =>
  axios
    .get(`${url}/agent`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const auditTaskLogs = (token: string, pageNumber: number) =>
  axios
    .get(`${url}/auditLogs/${pageNumber}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const auditFilterLogs = (token: string, requestFilterBody: object) =>
  axios
    .post(`${url}/auditLogs/filteredLogs`, requestFilterBody, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export default auditTaskLogs;
