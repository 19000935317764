import "./index.css";

import Sidebar from "./Sidebar";
import TopBar from "./TopBar";

function WithBars({ render }: { render: () => JSX.Element }) {
  return (
    <div className="container-div">
      <div className="sidebar">
        <Sidebar />
      </div>
      <TopBar />
      <div className="content">{render()}</div>
    </div>
  );
}

export default WithBars;
