import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { licenseCheck } from "../../controllers/license";
import { UserContext } from "../common/UserContext";

function LicenseExpired() {
  const history = useHistory();
  const { authToken } = useContext(UserContext);
  useEffect(() => {
    if (authToken === "") {
      history.push("/login");
    } else {
      licenseCheck().then((licenseData: { valid: boolean }) => {
        if (licenseData.valid) {
          history.push("/dashboard");
        }
      });
    }
  });
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        marginTop: "15rem",
        justifyContent: "center",
      }}
    >
      <div>
        <h1
          style={{
            marginBottom: "0.9rem",
            fontSize: "44px",
            fontWeight: "900",
            color: "#30287d",
            fontFamily: "sans-serif",
          }}
        >
          <b>Your License Has Been Expired</b>
        </h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginBottom: "0.9rem",
            justifyContent: "center",
          }}
        >
          <svg
            width="300px"
            height="300px"
            viewBox="0 0 48 48"
            version="1"
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 48 48"
          >
            <circle fill="#30287d" cx="17" cy="17" r="14" />
            <circle fill="#eee" cx="17" cy="17" r="11" />
            <rect x="16" y="8" width="2" height="9" />
            <rect
              x="18.2"
              y="16"
              transform="matrix(-.707 .707 -.707 -.707 46.834 19.399)"
              width="2.4"
              height="6.8"
            />
            <circle cx="17" cy="17" r="2" />
            <circle fill="#ffff" cx="17" cy="17" r="1" />
            <path
              fill="#FFC107"
              d="M11.9,42l14.4-24.1c0.8-1.3,2.7-1.3,3.4,0L44.1,42c0.8,1.3-0.2,3-1.7,3H13.6C12.1,45,11.1,43.3,11.9,42z"
            />
            <path
              fill="#263238"
              d="M26.4,39.9c0-0.2,0-0.4,0.1-0.6s0.2-0.3,0.3-0.5s0.3-0.2,0.5-0.3s0.4-0.1,0.6-0.1s0.5,0,0.7,0.1 s0.4,0.2,0.5,0.3s0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.6s0,0.4-0.1,0.6s-0.2,0.3-0.3,0.5s-0.3,0.2-0.5,0.3s-0.4,0.1-0.7,0.1 s-0.5,0-0.6-0.1s-0.4-0.2-0.5-0.3s-0.2-0.3-0.3-0.5S26.4,40.1,26.4,39.9z M29.2,36.8h-2.3L26.5,27h3L29.2,36.8z"
            />
          </svg>
        </div>
        <h2
          style={{
            textAlign: "center",
            fontSize: "38px",
            fontWeight: "800",
            color: "#30287d",
            fontFamily: "sans-serif",
          }}
        >
          <b>Please Contact Rap Team</b>
        </h2>
      </div>
    </div>
  );
}

export default LicenseExpired;
