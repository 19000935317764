import { forwardRef } from "react";

import RapIcon from "../../assets/icons/rapIcon.png";
import "./index.css";

const Loader = forwardRef<HTMLDivElement>(function Loader(_props, ref) {
  return (
    <div
      ref={ref}
      className="d-flex justify-content-center align-items-center"
      style={{ height: "73vh" }}
    >
      <div className="loader">
        <img src={RapIcon} alt="rap-icon" />
      </div>
    </div>
  );
});

export default Loader;
