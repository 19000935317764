import axios, { AxiosError } from "axios";

import { toastify } from "../components/common/notification";

import { url } from ".";

export type ConfigT = {
  id: string;
  value: { p: string; v: string; to?: string; vt: string }[];
};

export type TaskT = {
  _id: string;
  name: string;
  organization: { _id: string; name: string };
  created_by: {
    _id: string;
    name: string;
    email: string;
  };
  createdAt: string;
  updatedAt: string;
  totalVersions: number;
};

export type TaskItemT = {
  _id: string;
  name: string;
  description: string;
  manual_hour: string;
  versions: {
    version: number;
    flow: string;
    createdBy: {
      _id: string;
      name: string;
      email: string;
    };
    baseVersion: number;
    description?: string;
    startId: string;
    studioVersion: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
  }[];
  organization: string;
  created_by: {
    _id: string;
    name: string;
    email: string;
  };
  active_status: boolean;
  library: boolean;
  deleted_status: boolean;
  granted_agent_admins: string[];
  granted_developers: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
};

const apiErrorHandler = (error: AxiosError<{ message: string }>) => {
  let errorMessage = "Something went wrong";
  if (error.response?.data.message) {
    errorMessage = error.response.data.message;
  }
  toastify("failure", errorMessage);
};

export const taskListApi = (
  token: string,
  queryParams: { q?: string; page?: number } = {}
) => {
  const params = Object.entries(queryParams).reduce<string[][]>(
    (acc, entry) => {
      return [...acc, [entry[0].toString(), entry[1].toString()]];
    },
    []
  );
  const urlSearchParams = new URLSearchParams(params).toString();
  const searchParamString = urlSearchParams && `?${urlSearchParams}`;
  return axios
    .get<{ data: TaskT[]; max_task_version: number }>(
      `${url}/task${searchParamString}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch(apiErrorHandler);
};

export const getTaskApi = (token: string, taskId: string) => {
  return axios
    .get<{ data: [TaskItemT | undefined] }>(`${url}/task/${taskId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);
};

export const organizationTaskListApi = (token: string, orgId: string) =>
  axios
    .get(`${url}/task/org/${orgId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const botTaskListApi = (token: string, botId: string) =>
  axios
    .get(`${url}/task/bot/${botId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const organizationBotTaskListApi = (token: string, orgId: string) =>
  axios
    .get(`${url}/agent/${orgId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const schedulerTaskApi = (token: string, data: unknown) =>
  axios
    .post(`${url}/schedule/`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const scheduledTaskApi = (token: string, botId: string) =>
  axios
    .get(`${url}/task/bot/${botId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const retrieveScheduledData = (
  token: string,
  taskId: string,
  botId: string
) =>
  axios
    .get(`${url}/schedule/retrieve-data/${taskId}/${botId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const agentScheduleData = (
  token: string,
  botId: string,
  orgId: string
) =>
  axios
    .get(`${url}/schedule/organization/${orgId}/agent/${botId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const removeScheduleData = (
  token: string,
  id: string,
  taskId: string,
  agentId: string,
  orgId: string
) =>
  axios
    .delete(`${url}/schedule/remove/${id}/${taskId}/${agentId}/${orgId}`, {
      headers: {
        authorization: `Bearer ${token} `,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const updateScheduledData = (
  token: string,
  data: unknown,
  taskId: string,
  botId: string
) =>
  axios
    .put(`${url}/schedule/${taskId}/${botId}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const importTaskApi = (token: string, data: unknown, agent: string) =>
  axios
    .put(`${url}/agent/${agent}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const changeVersionApi = (
  token: string,
  data: unknown,
  orgId: string,
  agentId: string,
  taskId: string,
  versionNo: number
) =>
  axios
    .put(
      `${url}/agent/${orgId}/${agentId}/${taskId}/version/${versionNo}`,
      data,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const deleteTaskApi = (token: string, agentId: string, taskId: string) =>
  axios
    .delete(`${url}/agent/task/${agentId}/${taskId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const taskDeleteApi = (token: string, taskId: string) =>
  axios
    .delete(`${url}/task/${taskId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const taskVersionDeleteApi = (
  token: string,
  version: string,
  taskId: string
) =>
  axios
    .delete(`${url}/task/${taskId}/version/${version}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const moveTaskApi = (token: string, agentId: string, data: unknown) =>
  axios
    .put(`${url}/agent/task/move/${agentId}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const importTaskLibraryApi = (
  token: string,
  botId: string,
  data: unknown
) =>
  axios
    .put(`${url}/agent/library/${botId}`, data, {
      headers: {
        authorization: `Bearer ${token} `,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const getTaskConfig = (token: string, botId: string, taskId: string) =>
  axios
    .get(`${url}/agent/task/config/${botId}/${taskId}`, {
      headers: {
        authorization: `Bearer ${token} `,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const getAuthDetails = (token: string) =>
  axios
    .get(`${url}/emailAuth/getAuth`, {
      headers: {
        authorization: `Bearer ${token} `,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const putTaskConfig = (
  token: string,
  botId: string,
  taskId: string,
  data: { config: ConfigT[] }
) =>
  axios
    .put(`${url}/agent/task/config/${botId}/${taskId}`, data, {
      headers: {
        authorization: `Bearer ${token} `,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const putMailConfig = (
  token: string,
  botId: string,
  taskId: string,
  data: unknown
) =>
  axios
    .put(`${url}/agent/task/mail/${botId}/${taskId}`, data, {
      headers: {
        authorization: `Bearer ${token} `,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);

export const pushTaskInTaskLibrary = (
  token: string,
  data: unknown,
  organization: string
) =>
  axios
    .post(`${url}/task/${organization}`, data, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch(apiErrorHandler);
