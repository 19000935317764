import {
  ChangeEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dropdown, Form, InputGroup, Table } from "react-bootstrap";
import { Calendar, DateObject } from "react-multi-date-picker";

import CalenderIcon from "../../assets/icons/calendarIcon.svg";
import {
  agentListApi,
  auditFilterLogs,
  auditTaskLogs,
} from "../../controllers/auditLogs";
import { organizationListApi } from "../../controllers/organization";
import { taskListApi } from "../../controllers/task";
import { userListApi } from "../../controllers/users";
import { dateFormatter, timeFormatter } from "../common/dateFormat";
import NoDataFound from "../common/NoDataFound";
import { UserContext } from "../common/UserContext";

type AuditLogT = {
  _id: string;
  entityId: string;
  actionedBy: { name: string };
  action: string;
  entity: string;
  changeLog?: {
    from?: Record<string, string> & { task?: string[] };
    to?: {
      role?: string;
      name?: string;
      type?: string;
      timezone?: string;
      queueName?: string;
      organization?: string[];
      email?: string;
      granted_organization?: string[];
      license?: {
        single_task_agent?: number;
        multiple_task_agent?: number;
      };
      schedulerData?: Record<string, string> & {
        organization?: string[];
        agent?: string[];
        selected_days?: string[];
        selected_dates?: string[];
        scheduled_dates?: string[];
      };
      active_status?: boolean;
      task?: string[];
      start_date?: string;
      end_date: string;
      recurrence: string;
      ocurrence: string;
      repeat: string;
      selected_days: string[];
      start_time: string;
      end_time: string;
      selected_dates: string[];
    };
  };
  createdAt: string;
};

function AuditLogs() {
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const { authToken } = useContext(UserContext);
  const [auditData, setAuditData] = useState<AuditLogT[]>([]);
  const [orgList, setOrgList] = useState<{ _id: string; name: string }[]>([]);
  const [userList, setUserList] = useState<
    { _id: string; name: string; role: string }[]
  >([]);
  const [taskList, setTaskList] = useState<{ _id: string; name: string }[]>([]);
  const [agentList, setAgentList] = useState<{ _id: string; name: string }[]>(
    []
  );
  const [userOption, setUserOption] = useState<string[]>([]);
  const [orgOption, setOrgOption] = useState<string[]>([]);
  const [entityOption, setEntityOption] = useState<string[]>([]);
  const [filterResult, setFilterResult] = useState<AuditLogT[]>(auditData);
  const [scheduleDate, setScheduleDate] = useState<string[]>([]);
  const [showCalender, setShowCalender] = useState(false);

  const lastLogElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const getUserRole = (user: { role: string; name: string }) => {
    let userRole;
    if (user.role === "organization_admin") {
      userRole = `${user.name} (Org admin)`;
    } else if (user.role === "developer") {
      userRole = `${user.name} (Dev)`;
    }
    return userRole;
  };

  const formatSelectedDays = (days: Array<string>) => {
    let selectedDays;
    if (days.length === 1) {
      selectedDays = days;
    } else if (days.length === 2) {
      selectedDays = days.join(" and ");
    } else {
      selectedDays = days.join(", ");
    }
    return selectedDays;
  };

  const formatSelectedTime = (time: string) => {
    const selectedTime = time.split(" ");
    return selectedTime[1].concat(":", selectedTime[0]);
  };

  const getAgentStatus = (status: boolean) => {
    let agentStatus;
    if (status) {
      agentStatus = "Activated";
    } else {
      agentStatus = "Deactivated";
    }
    return agentStatus;
  };
  const getRole = (role: string) => {
    let roleName;
    if (role === "organization_admin") {
      roleName = "Organization Admin";
    } else if (role === "super_admin") {
      roleName = "Super Admin";
    } else if (role === "developer") {
      roleName = "Developer";
    } else if (role === "organization") {
      roleName = "Organization";
    } else if (role === "agent") {
      roleName = "Agent";
    } else if (role === "user") {
      roleName = "User";
    } else if (role === "scheduler") {
      roleName = "Scheduler";
    } else if (role === "queue") {
      roleName = "Queue";
    } else if (role === "apiKey") {
      roleName = "ApiKey";
    } else if (role === "task") {
      roleName = "Task";
    } else if (role === "agent_admin") {
      roleName = "Agent Admin";
    }
    return roleName;
  };

  const entityList = [
    "user",
    "organization",
    "agent",
    "scheduler",
    "task",
    "queue",
    "apiKey",
  ];

  const getOrgName = (orgId: string[]) => {
    let organizationName;
    orgList.forEach((orgName) => {
      if (orgId.includes(orgName._id)) {
        organizationName = orgName.name;
      }
    });
    return organizationName;
  };

  const getTaskName = (taskId: string[]) => {
    let taskName;
    taskList.forEach((task) => {
      if (taskId.includes(task._id)) {
        taskName = task.name;
      }
    });
    return taskName;
  };

  const getAgentName = (agentId: string[]) => {
    let agentName;
    agentList.forEach((agent) => {
      if (agentId.includes(agent._id)) {
        agentName = agent.name;
      }
    });
    return agentName;
  };

  function getListData(data: AuditLogT) {
    let listData;
    if (data.action === "create" && data.changeLog?.to) {
      if (data.entity === "user") {
        listData = (
          <div>
            <li>Name: {data.changeLog.to.name}</li>
            <li>Email: {data.changeLog.to.email}</li>
            <li>Role: {getRole(data.changeLog.to?.role || "")}</li>
            {data.changeLog.to.role !== "super_admin" ? (
              <li>
                Grant Access:{" "}
                {getOrgName(data.changeLog.to?.granted_organization || [])}
              </li>
            ) : (
              <div> </div>
            )}
          </div>
        );
      } else if (data.entity === "organization" && data.changeLog?.to.license) {
        listData = (
          <div>
            <li>Name: {data.changeLog.to.name}</li>
            <li>
              Single Task Agents: {data.changeLog.to.license.single_task_agent}{" "}
            </li>
            <li>
              Multiple Task Agents:
              {data.changeLog.to.license.multiple_task_agent}{" "}
            </li>
          </div>
        );
      } else if (data.entity === "agent") {
        listData = (
          <div>
            <li>Agent Name: {data.changeLog.to.name}</li>
            <li>Agent Type: {data.changeLog.to.type}</li>
            <li>Agent Time Zone: {data.changeLog.to.timezone}</li>
          </div>
        );
      } else if (data.entity === "queue" || data.entity === "apiKey") {
        listData = (
          <div>
            {data.entity === "queue" ? (
              <li>Queue Name: {data.changeLog.to.queueName}</li>
            ) : (
              <div> </div>
            )}
            <li>
              Organization: {getOrgName(data.changeLog.to?.organization || [])}
            </li>
          </div>
        );
      } else if (data.entity === "task") {
        listData = (
          <div>
            <li>Task Name: {data.changeLog.to.name}</li>
            <li>
              Organization: {getOrgName(data.changeLog.to?.organization || [])}
            </li>
          </div>
        );
      } else if (data.entity === "scheduler") {
        listData = (
          <div>
            {data.changeLog.to.schedulerData?.organization ? (
              <li>
                Organization :{" "}
                {getOrgName(data.changeLog.to.schedulerData.organization)}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.agent ? (
              <li>
                Agent : {getAgentName(data.changeLog.to.schedulerData.agent)}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.start_date ? (
              <li>
                Start Date :{" "}
                {data.changeLog.to.schedulerData.start_date.replaceAll(
                  " ",
                  "-"
                )}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.end_date ? (
              <li>
                End Date :{" "}
                {data.changeLog.to.schedulerData.end_date.replaceAll(" ", "-")}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.recurrence ? (
              <li>Recurrence : {data.changeLog.to.schedulerData.recurrence}</li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.occurrence ? (
              <li>Ocurrence : {data.changeLog.to.schedulerData.ocurrence}</li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.repeat ? (
              <li>Repeat : {data.changeLog.to.schedulerData.repeat}</li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.selected_days &&
            data.changeLog.to.schedulerData.selected_days.length > 0 ? (
              <li>
                Selected Days :{" "}
                {formatSelectedDays(
                  data.changeLog.to.schedulerData.selected_days
                )}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.start_time ? (
              <li>
                Start Time :{" "}
                {formatSelectedTime(data.changeLog.to.schedulerData.start_time)}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.end_time ? (
              <li>
                End Time :{" "}
                {formatSelectedTime(data.changeLog.to.schedulerData.end_time)}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.selected_dates ? (
              <li>
                Selected Dates :{" "}
                {formatSelectedDays(
                  data.changeLog.to.schedulerData.selected_dates
                )}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.execution_type ? (
              <li>
                Execution Type :{" "}
                {data.changeLog.to.schedulerData.execution_type}
              </li>
            ) : (
              <div> </div>
            )}
            {data.changeLog.to.schedulerData?.scheduled_dates ? (
              <li>
                Scheduled Dates :{" "}
                {formatSelectedDays(
                  data.changeLog.to.schedulerData.scheduled_dates
                )}
              </li>
            ) : (
              <div> </div>
            )}
          </div>
        );
      }
    } else if (data.action === "update") {
      if (data.entity === "user") {
        listData = (
          <div>
            {data.changeLog?.to?.name ? (
              <li>Name to: {data.changeLog.to.name}</li>
            ) : (
              <div>
                <li>No Updates</li>
              </div>
            )}
          </div>
        );
      } else if (data.entity === "organization") {
        listData = (
          <div>
            {data.changeLog ? (
              <div>
                {data.changeLog?.to?.name ? (
                  <li>Name to: {data.changeLog.to.name}</li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.license?.single_task_agent ? (
                  <li>
                    Single Task Agents:{" "}
                    {data.changeLog.to.license.single_task_agent}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.license?.multiple_task_agent ? (
                  <li>
                    Multiple Task Agents:
                    {data.changeLog.to.license.multiple_task_agent}
                  </li>
                ) : (
                  <div> </div>
                )}
              </div>
            ) : (
              <div>
                <li>No Updates</li>
              </div>
            )}
          </div>
        );
      } else if (data.entity === "agent") {
        listData = (
          <div>
            {data.changeLog ? (
              <div>
                {data.changeLog?.from?.task ? (
                  <li>Task: {getTaskName(data.changeLog.from.task)}</li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.name ? (
                  <li>Name to: {data.changeLog.to.name}</li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.active_status ? (
                  <li>
                    Status: {getAgentStatus(data.changeLog.to.active_status)}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.task ? (
                  <li>Task: {getTaskName(data.changeLog.to.task)}</li>
                ) : (
                  <div> </div>
                )}
              </div>
            ) : (
              <div>
                <li>No Updates</li>
              </div>
            )}
          </div>
        );
      } else if (data.entity === "scheduler") {
        listData = (
          <div>
            {"changeLog" in data ? (
              <div>
                {data.changeLog?.to?.start_date ? (
                  <li>
                    Start Date :{" "}
                    {data.changeLog.to.start_date.replaceAll(" ", "-")}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.end_date ? (
                  <li>
                    End Date : {data.changeLog.to.end_date.replaceAll(" ", "-")}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.recurrence ? (
                  <li>Recurrence : {data.changeLog.to.recurrence}</li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.ocurrence ? (
                  <li>Ocurrence : {data.changeLog.to.ocurrence}</li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.repeat ? (
                  <li>Repeat : {data.changeLog.to.repeat}</li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.selected_days ? (
                  <li>
                    Selected Days :{" "}
                    {formatSelectedDays(data.changeLog.to.selected_days)}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.start_time ? (
                  <li>
                    Start Time :{" "}
                    {formatSelectedTime(data.changeLog.to.start_time)}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.end_time ? (
                  <li>
                    End Time : {formatSelectedTime(data.changeLog.to.end_time)}
                  </li>
                ) : (
                  <div> </div>
                )}
                {data.changeLog?.to?.selected_dates ? (
                  <li>
                    Selected Dates :{" "}
                    {formatSelectedDays(data.changeLog.to.selected_dates)}
                  </li>
                ) : (
                  <div> </div>
                )}
              </div>
            ) : (
              <div>
                <li>No Updates</li>
              </div>
            )}
          </div>
        );
      }
    }
    return listData;
  }

  const getSentenceData = (data: AuditLogT) => {
    let sentenceData;
    if (data.action === "create") {
      sentenceData = `${data.actionedBy.name} has created new ${
        data.entity === "user" && data.changeLog?.to?.role
          ? getRole(data.changeLog.to.role)
          : getRole(data.entity)
      } with following details on ${dateFormatter(
        data.createdAt
      )} at ${timeFormatter(data.createdAt)}`;
    }
    if (data.action === "update") {
      sentenceData = `${
        data.actionedBy.name
      } has updated following details of ${getRole(
        data.entity
      )} on ${dateFormatter(data.createdAt)} at ${timeFormatter(
        data.createdAt
      )}`;
    }
    if (data.action === "delete") {
      sentenceData = `${data.actionedBy.name} has deleted ${data.entity} ${
        data.entityId
      } on ${dateFormatter(data.createdAt)} at ${timeFormatter(
        data.createdAt
      )}`;
    }
    return sentenceData;
  };

  const calendarChangeHandler = (value: DateObject | DateObject[] | null) => {
    if (value && Array.isArray(value)) {
      setScheduleDate(
        value.map((calenderItem: DateObject) => {
          return `${calenderItem.year}-${calenderItem.month.number}-${calenderItem.day}`;
        })
      );
    }
  };

  const handleOrgOption = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setOrgOption(orgOption?.concat(event.target.value));
    } else {
      const actualData = orgOption.filter(
        (item) => item !== event.target.value
      );
      setOrgOption(actualData);
    }
  };

  const handleUserOption = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setUserOption(userOption?.concat(event.target.value));
    } else {
      const actualData = userOption.filter(
        (item) => item !== event.target.value
      );
      setUserOption(actualData);
    }
  };

  const handleEntityOption = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setEntityOption(entityOption?.concat(event.target.value));
    } else {
      const actualData = entityOption.filter(
        (item) => item !== event.target.value
      );
      setEntityOption(actualData);
    }
  };

  useEffect(() => {
    function filterRequestBody() {
      const requestBody: Record<string, object> = {};
      if (orgOption.length > 0) {
        Object.assign(requestBody, { organization: { $all: orgOption } });
      }
      if (userOption.length > 0) {
        Object.assign(requestBody, { "actionedBy.name": { $all: userOption } });
      }
      if (entityOption.length > 0) {
        Object.assign(requestBody, { entity: { $all: entityOption } });
      }
      if (scheduleDate.length === 2) {
        const startDate = scheduleDate[0];
        const endDate = scheduleDate[1];
        Object.assign(requestBody, {
          createdAt: { $gte: startDate, $lt: endDate },
        });
      }
      return requestBody;
    }
    const reqData = filterRequestBody();
    setError(false);
    auditFilterLogs(authToken, reqData)
      .then((res) => {
        if (Object.keys(res.data).length !== 0) {
          setFilterResult(res.data);
        } else if (res.data.length === 0) {
          setFilterResult(res.data);
        } else {
          setFilterResult(auditData);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [auditData, authToken, entityOption, orgOption, scheduleDate, userOption]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    Promise.all([
      auditTaskLogs(authToken, pageNumber),
      userListApi(authToken),
      organizationListApi(authToken),
      taskListApi(authToken),
      agentListApi(authToken),
    ])
      .then((response) => {
        const auditResult = response[0];
        const userListResult = response[1];
        const organizationList = response[2];
        const getTaskList = response[3];
        const getAgentList = response[4];
        setAuditData((prevAuditData) => [
          ...prevAuditData,
          ...auditResult.data,
        ]);

        setOrgList(organizationList.data);
        setUserList(userListResult.data);
        setTaskList(getTaskList?.data || []);
        setAgentList(getAgentList.data);
        setHasMore(auditResult.data.length > 0);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  }, [authToken, pageNumber]);
  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      <h1 style={{ textAlign: "center" }}>Audit Logs</h1>
      <div className="d-flex" style={{ justifyContent: "flex-end" }}>
        <div className="pad-button">
          <InputGroup className="mb-3">
            <Form.Control
              className="radioButtonLabel"
              placeholder="Select Start Date and End Date"
              value={scheduleDate.map((item) => item) || ""}
              onChange={() => setScheduleDate([])}
            />
            <InputGroup.Text
              id="basic-addon2"
              onClick={() => {
                setShowCalender(!showCalender);
              }}
            >
              <img src={CalenderIcon} alt="CalendarIcon" />
            </InputGroup.Text>
          </InputGroup>
          {showCalender && (
            <div style={{ position: "absolute" }}>
              <Calendar
                range
                value={scheduleDate.map((item) => item) || ""}
                // editable
                format="YYYY-MM-DD"
                onChange={calendarChangeHandler}
              />
            </div>
          )}
        </div>
        {orgList.length > 0 && (
          <div className="pad-button">
            <Dropdown style={{ paddingBottom: "10px" }}>
              <Dropdown.Toggle className="dropdown-org buttons text">
                Organization
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-space navbar-collapse">
                {orgList.map((orgData) => {
                  return (
                    <label
                      key={orgData._id}
                      className="dropdown-option org-item"
                      htmlFor={orgData._id}
                    >
                      <input
                        type="checkbox"
                        value={orgData._id}
                        data-value={orgData.name}
                        onChange={handleOrgOption}
                        id={orgData._id}
                        className="check-box"
                      />{" "}
                      {orgData.name}
                    </label>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {userList.length > 0 && (
          <div className="pad-button">
            <Dropdown style={{ paddingBottom: "10px" }}>
              <Dropdown.Toggle className="dropdown-org buttons text">
                User
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-space navbar-collapse">
                {userList.map((userData) => {
                  return (
                    <label
                      key={userData._id}
                      className="dropdown-option org-item"
                      htmlFor={userData._id}
                    >
                      <input
                        type="checkbox"
                        value={userData.name}
                        data-value={userData.name}
                        onChange={handleUserOption}
                        id={userData._id}
                        className="check-box"
                      />{" "}
                      {getUserRole(userData)}
                    </label>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {entityList.length > 0 && (
          <div className="pad-button">
            <Dropdown style={{ paddingBottom: "10px" }}>
              <Dropdown.Toggle className="dropdown-org buttons text">
                Entity
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-space navbar-collapse">
                {entityList.map((entityData) => {
                  return (
                    <label
                      key={entityData}
                      className="dropdown-option org-item"
                      htmlFor={entityData}
                    >
                      <input
                        type="checkbox"
                        value={entityData}
                        data-value={entityData}
                        onChange={handleEntityOption}
                        id={entityData}
                        className="check-box"
                      />{" "}
                      {entityData}
                    </label>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      {filterResult.length === 0 ? (
        <NoDataFound message1="No Audit logs found" message2="" />
      ) : (
        <div>
          <Table className="table" borderless>
            <thead className="table-head">
              <tr>
                <th> </th>
                <th>S.No</th>
                <th>Audit Logs Data</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {filterResult.map((data, index) => {
                const ref =
                  filterResult.length === index + 1
                    ? lastLogElementRef
                    : undefined;
                return (
                  <Fragment key={data._id}>
                    <tr ref={ref}>
                      <td> </td>
                      <td>{index + 1}</td>
                      <td>
                        {getSentenceData(data)}
                        <div style={{ paddingLeft: "20px" }}>
                          {getListData(data)}
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
              {loading && (
                <tr>
                  <td className="text-center" colSpan={5}>
                    Loading...
                  </td>
                </tr>
              )}
              {error && (
                <tr>
                  <td> Error</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default AuditLogs;
