import "bootstrap/dist/css/bootstrap.min.css";

import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AuditLogs from "./components/AuditLogs/AuditLogs";
import Loader from "./components/common/Loader";
import { UserContext } from "./components/common/UserContext";
import WithBars from "./components/common/WithBars";
import LicenseExpired from "./components/License/LicenseExpired";
import SignLicense from "./components/License/SignLicense";
import FormSubmit from "./components/Organization/FormSubmit";
import { licenseCheck } from "./controllers/license";
import sidebarItems from "./controllers/sidebarElements";
import { selfUser } from "./controllers/users";
import { catchHandler } from "./utils";

// import BotScreen from './components/Organization/BotScreen';
// import BotRun from './components/Organization/BotRun';

const Login = lazy(() => import("./components/Login/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const Calendar = lazy(() => import("./components/calendar/Calendar"));
const BotScreen = lazy(() => import("./components/Organization/BotScreen"));
const BotRun = lazy(() => import("./components/Organization/BotRun"));
const FormBuilderModal = lazy(
  () => import("./components/Organization/FormBuilderModal")
);
const UserList = lazy(() => import("./components/User/UserList"));
const OrganizationDetails = lazy(
  () => import("./components/Organization/OrganizationDetails")
);

const BotDetails = lazy(() => import("./components/BotDetails/BotDetails"));
const CreateUser = lazy(() => import("./components/User/CreateUser"));
const TaskLibrary = lazy(() => import("./components/Task/TaskLibrary"));
const OrganizationList = lazy(
  () => import("./components/Organization/OrganizationList")
);

const OrgDashboard = lazy(() => import("./components/Dashboard/OrgDashboard"));
const ForgetPassword = lazy(() => import("./components/ForgetPassword"));
const ResetPassword = lazy(
  () => import("./components/ResetPassword/ResetPassword")
);
const UpdatePassword = lazy(
  () => import("./components/UpdatePassword/UpdatePassword")
);

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

function ProtectedRoute({
  isAuthenticated,
  path,
  render,
  exact,
}: ProtectedRouteProps) {
  if (isAuthenticated) {
    return <Route path={path} render={render} exact={exact} />;
  }
  return <Redirect to={{ pathname: "/login" }} />;
}

function App() {
  const [user, setUser] = useState({
    id: "",
    email: "",
    name: "",
    role: "",
    granted_organization: [] as string[],
    authToken: "",
  });

  const [loading, setLoading] = useState(true);

  const [route, setRoute] = useState("login");

  const userContextValue = useMemo(
    () => ({
      name: user.name,
      role: user.role,
      email: user.email,
      authToken: user.authToken,
      granted_organization: user.granted_organization,
      setUser,
    }),
    [user, setUser]
  );

  // useEffect(() => {
  //   const firstElement = sidebarItems.find((item) =>
  //     item.users?.includes(user.role)
  //   );

  //   const sideItems = sidebarItems.filter((item) =>
  //     item.users?.includes(user.role)
  //   );

  //   // setAllowedRoutes(sideItems.map((item) => item.route));

  //   setRoute(firstElement?.route || '/login');

  //   setTimeout(() => setLoading(false), 100);
  // }, [user]);

  useEffect(() => {
    const token = localStorage.getItem("$AuthToken");
    if (token) {
      selfUser(token)
        .then((data) => {
          if (!data) {
            localStorage.removeItem("$AuthToken");
          } else {
            setUser({
              id: data.id,
              email: data.email,
              name: data.name,
              role: data.role,
              granted_organization: data.granted_organization,
              authToken: token,
            });

            const firstElement = sidebarItems.find((item) =>
              item.users?.includes(data.role)
            );

            // const sideItems = sidebarItems.filter((item) =>
            //     item.users?.includes(data.role)
            // );

            licenseCheck()
              .then((licenseData: { valid: boolean }) => {
                if (licenseData.valid) {
                  setRoute(firstElement?.route || "/");
                } else {
                  setRoute("/get-license");
                }
              })
              .catch(() => {
                setRoute("/license-expired");
              });
          }
          setTimeout(() => setLoading(false), 100);
        })
        .catch((error: unknown) => {
          setLoading(false);
          catchHandler(error);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const isAuthenticate = () => {
    return user.email !== "";
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Router>
        <UserContext.Provider value={userContextValue}>
          <div className="container-div">
            <Switch>
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/dashboard"
                render={() => (
                  <WithBars
                    render={() => (
                      <div>
                        {user.role === "super_admin" ? (
                          <Suspense fallback={<div />}>
                            <Dashboard />
                          </Suspense>
                        ) : (
                          <Suspense fallback={<div />}>
                            <OrgDashboard />
                          </Suspense>
                        )}
                      </div>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/botscreen"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <BotScreen />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation/:org_id/:agent_id/logs"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <BotRun />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/users"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <UserList />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/users/create"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <CreateUser />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <Route
                path="/forget-password"
                render={() => (
                  <Suspense fallback={<div />}>
                    <ForgetPassword />
                  </Suspense>
                )}
                exact
              />
              <Route
                path="/reset-password/"
                render={() => (
                  <Suspense fallback={<div />}>
                    <ResetPassword />
                  </Suspense>
                )}
                exact
              />
              <Route
                path="/update-password/"
                render={() => (
                  <Suspense fallback={<div />}>
                    <UpdatePassword />
                  </Suspense>
                )}
                exact
              />
              <Route
                path="/reset-password/:token"
                render={() => (
                  <Suspense fallback={<div />}>
                    <ResetPassword />
                  </Suspense>
                )}
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/users/edit/:id"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <CreateUser />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/task_library"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <TaskLibrary />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />

              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation/:org_id/:agent_id/calendar"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <Calendar />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/audit_logs"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <AuditLogs />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <OrganizationList />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation/:id"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <OrganizationDetails />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation/:org_id/:agent_id"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <BotDetails />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />
              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation/:org_id/:queueId/buildForm"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <FormBuilderModal />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />

              <ProtectedRoute
                isAuthenticated={isAuthenticate()}
                path="/organisation/:org_id/:queueId/EditForm/:formId"
                render={() => (
                  <WithBars
                    render={() => (
                      <Suspense fallback={<div />}>
                        <FormBuilderModal />
                      </Suspense>
                    )}
                  />
                )}
                exact
              />

              <Route
                path="/login"
                render={() => (
                  <Suspense fallback={<div />}>
                    <Login setUser={setUser} />
                  </Suspense>
                )}
                exact
              />

              <Route
                path="/submit-form/:formId"
                render={() => (
                  <Suspense fallback={<div />}>
                    <FormSubmit />
                  </Suspense>
                )}
              />

              <Route
                path="/license-expired"
                render={() => (
                  <Suspense fallback={<div />}>
                    <LicenseExpired />
                  </Suspense>
                )}
              />

              <Route
                path="/get-license"
                render={() => (
                  <Suspense fallback={<div />}>
                    <SignLicense />
                  </Suspense>
                )}
              />

              {isAuthenticate() ? (
                <Redirect to={`/${route}`} />
              ) : (
                <Redirect to="/login" />
              )}
            </Switch>
          </div>
        </UserContext.Provider>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
