import axios from "axios";

import { toastify } from "../components/common/notification";

import { url } from ".";

export const createFormApi = (token: string, data: unknown) =>
  axios
    .post(`${url}/form`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const getFormApi = (formId: string) =>
  axios
    .get(`${url}/form/metadata/${formId}`)
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const getOrgFormsApi = (orgId: string, token: string) =>
  axios
    .get(`${url}/form/organization/${orgId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const updateFormApi = (token: string, formId: string, data: unknown) =>
  axios
    .put(`${url}/form/${formId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const deleteFormApi = (token: string, formId: string) =>
  axios
    .delete(`${url}/form/${formId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const submitFormApi = (queueName: string, data: unknown) =>
  axios
    .put(`${url}/queue/form/${queueName}`, data)
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );

export const getEditFormAPI = (formId: string, token: string) =>
  axios
    .get(`${url}/form/${formId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) =>
      toastify(
        "failure",
        error.response.data.message?.length > 0
          ? error.response.data.message
          : "Something went wrong"
      )
    );
